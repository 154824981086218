import React, { Component } from 'react';

import Style from './AIScript.module.less';
import FJSvg from '../../../FJSvg/FJSvg';
import ArrowSvg from '../../../../src/assets/icon/arrow.svg';
import FJUtil from '../../../../src/util/FJUtil';
import FJConfig from '../../../../src/config/FJConfig';

export default class AIScript extends Component {
  constructor(props) {
    super(props);
    this.state = {
      keyword: '',
      isImageLoaded: 0,
    };
    this.timer1 = null;
  }

  jumpToEditPage = () => {
    FJUtil.ealog('home jump', {
      'click name': 'Al Script',
    });
    window.location.href = FJUtil.getCurrentUrl() + '/editor/app?tools=ai-script';
  };

  imgAnimation(e) {
    e.target.style.visibility = 'visible';
    e.target.style.opacity = '1';
    e.target.style.transform = 'translateY(0)';

    this.timer1 = setTimeout(() => {
      this.observerDom.style.visibility = 'visible';
      this.observerDom.style.opacity = '1';
      this.observerDom.style.bottom = '7%';
    }, 500);
  }

  componentDidMount() {
    this.setState({
      isImageLoaded: this.state.isImageLoaded + 1,
    });
  }

  componentWillUnmount() {
    this.timer1 && clearTimeout(this.timer1);
  }

  render() {
    const { data = {}, language = '' } = this.props;

    const imgPrefix = `https://resource.flexclip.com/pages/home/choiceness/${language === 'jp' ? 'jp-' : ''}`;
    const mainImg = imgPrefix + `AIScript.webp?v=${FJConfig.buildVersion}`;
    return (
      <div className={Style.container}>
        <img
          key={this.state.isImageLoaded}
          draggable={false}
          src={mainImg}
          width={1017}
          height={642}
          alt='AI Script img'
          onLoad={e => {
            this.imgAnimation(e);
          }}
        />

        <button className={Style.button} ref={e => (this.observerDom = e)} onClick={this.jumpToEditPage}>
          {data['button-1'] || 'Try AI Script'}
          <FJSvg src={ArrowSvg} />
        </button>
      </div>
    );
  }
}
