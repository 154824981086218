import { Component } from 'react';
import FJConfig from '../../../../src/config/FJConfig';
import classname from 'classnames';

import Style from './AIBGRemover.module.less';

import FJSvg from '../../../FJSvg/FJSvg';
import ArrowRightSvg from '../../../../src/assets/icon/arrow.svg';
import FJLocalStore from '../../../../src/store/FJLocalStore';
import FJUtil from '../../../../src/util/FJUtil';

export default class AIBGRemover extends Component {
  constructor(props) {
    super(props);
    this.state = {
      keyword: '',
      isImageLoaded: 0,
    };

    this.inputAccept = [
      '3gp',
      'asf',
      'avi',
      'f4v',
      'flv',
      'hevc',
      'm2ts',
      'm2v',
      'm4v',
      'mjpeg',
      'mkv',
      'mov',
      'mp4',
      'mpeg',
      'mpg',
      'mts',
      'mxf',
      'ogv',
      'rm',
      'swf',
      'ts',
      'vob',
      'webm',
      'wmv',
      'wtv',
      '3g2',
      'jpeg',
      'jpg',
      'png',
      'tif',
      'tiff',
      'bmp',
      'webp',
      'apng',
      'raw',
      'pcd',
      'psd',
      'ai',
    ];
  }

  dealInputValue = e => {
    this.setState({
      keyword: e.target.value.trim(),
    });
  };

  componentDidMount() {
    setTimeout(() => {
      this.setState({
        isImageLoaded: this.state.isImageLoaded + 1,
      });
    });
  }

  chooseFile = () => {
    this.inputRef.click();
  };

  jumpUrl(uploadKey, language = 'en') {
    FJUtil.ealog('home jump', {
      'click name': 'Al BG Remover',
    });
    return FJUtil.getCurrentUrl() + '/editor/app?input_video=' + uploadKey;
  }

  saveFileThenJump(files) {
    const fileName = files[0].name;

    const fileType = fileName ? fileName.split('.').slice(-1)[0] : null;
    if (!this.inputAccept.includes(fileType.toLowerCase())) {
      return window.fjmessage && window.fjmessage.addMessage(FJLocalStore._('errFile'), 'error');
    }

    const params = {
      module: 'media',
      showTrim: false,
      supportImage: true,
    };

    FJUtil.readFiles(files, params, this.jumpUrl, 'files');
  }

  render() {
    const { data = {}, language = '' } = this.props;
    const imgPrefix = `https://resource.flexclip.com/pages/home/choiceness/${language === 'jp' ? 'jp-' : ''}`;
    const intro1 = imgPrefix + `AIBGRemover-intro1.webp?v=1123515`;
    const intro2 = imgPrefix + `AIBGRemover-intro2.${language === 'jp' ? 'webp' : 'svg'}??v=1123515`;

    return (
      <div className={Style.container}>
        <img
          className={classname(Style.mainBg, { [Style.mainBgAnimation]: this.state.isImageLoaded > 0 })}
          draggable={false}
          src={`https://resource.flexclip.com/pages/home/choiceness/AIBGRemover.webp?v=${FJConfig.buildVersion}`}
          width={943}
          height={642}
          alt='AI BG Remover img'
        />
        <img
          className={classname(Style.main, { [Style.mainBgAnimation]: this.state.isImageLoaded > 0 })}
          draggable={false}
          src={`https://resource.flexclip.com/pages/home/choiceness/AIBGRemover-main.webp?v=${FJConfig.buildVersion}`}
          width={521}
          height={723}
          alt='AI BG Remover main img'
        />
        <div className={classname(Style.topGuidBox, { [Style.topGuidBoxAnimation]: this.state.isImageLoaded > 0 })}>
          <img draggable={false} src={intro2} width={569} height={42} alt='AI BG Remover guid img' />
        </div>
        <div className={classname(Style.inputBox, { [Style.inputBoxAnimation]: this.state.isImageLoaded > 0 })}>
          <button className={Style.button} onClick={this.chooseFile}>
            {data['button-1' || 'Remove Background']}
            <FJSvg src={ArrowRightSvg} />
          </button>
        </div>
        <div
          className={classname(Style.floatContainer, { [Style.floatContainerAnimation]: this.state.isImageLoaded > 0 })}
        >
          <img draggable={false} src={intro1} width={268} height={239} alt='AI BG Remover intro1' />
        </div>

        <input
          ref={e => (this.inputRef = e)}
          type='file'
          accept={this.inputAccept.map(item => '.' + item).join(',')}
          style={{ display: 'none' }}
          onClick={e => {
            e.target.value = null;
          }}
          onChange={e => {
            this.saveFileThenJump(e.target.files);
          }}
        />
      </div>
    );
  }
}
