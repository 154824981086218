import { Component } from 'react';

import Style from './Translate.module.less';
import FJSvg from '../../../FJSvg/FJSvg';
import TranslateSvg from '../../../../src/assets/icon/Translate.svg';
import FJUtil from '../../../../src/util/FJUtil';
import classname from 'classnames';
import FJConfig from '../../../../src/config/FJConfig';
import FJLocalStore from '../../../../src/store/FJLocalStore';

export default class Translate extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isImageLoaded: 0,
      startAnimation: false,
    };

    this.inputAccept = [
      '3gp',
      'asf',
      'avi',
      'f4v',
      'flv',
      'hevc',
      'm2ts',
      'm2v',
      'm4v',
      'mjpeg',
      'mkv',
      'mov',
      'mp4',
      'mpeg',
      'mpg',
      'mts',
      'mxf',
      'ogv',
      'rm',
      'swf',
      'ts',
      'vob',
      'webm',
      'wmv',
      'wtv',
      '3g2',
      'mp3',
      'm4a',
      'wav',
      'wma',
      'flac',
      'aac',
      'ogg',
      'opus',
    ];
  }

  componentDidMount() {
    this.changeState();
  }

  changeState = () => {
    this.setState({
      isImageLoaded: this.state.isImageLoaded + 1,
    });
  };

  componentWillUnmount() {
    this.timer1 && clearTimeout(this.timer1);
    this.timer2 && clearTimeout(this.timer2);
  }

  chooseFile = () => {
    this.inputRef.click();
  };

  jumpUrl(uploadKey, language = 'en') {
    FJUtil.ealog('home jump', {
      'click name': 'Al Translator',
    });
    return FJUtil.getCurrentUrl() + '/editor/app?rec_id=' + uploadKey;
  }

  saveFileThenJump(files) {
    const fileName = files[0].name;
    const fileType = fileName ? fileName.split('.').slice(-1)[0] : null;
    if (!this.inputAccept.includes(fileType.toLowerCase())) {
      return window.fjmessage && window.fjmessage.addMessage(FJLocalStore._('errFile'), 'error');
    }

    const params = {
      module: 'translate',
      language: 'en-SG',
      pitch: '1',
      speed: '1',
      voice: 'en-US-JennyNeural',
      voiceStyle: 'general',
      applyTimeline: true,
    };

    FJUtil.readFiles(files, params, this.jumpUrl);
  }

  render() {
    const { data = {}, language } = this.props;

    const imgPrefix = `https://resource.flexclip.com/pages/home/choiceness/${language === 'jp' ? 'jp-' : ''}`;
    const leftImg = imgPrefix + `Translate-left.webp?v=${FJConfig.buildVersion}`;
    return (
      <div className={Style.container}>
        <img
          key={this.state.isImageLoaded}
          draggable={false}
          src='https://resource.flexclip.com/pages/home/choiceness/Translate.webp'
          width={845}
          height={660}
          alt='text to speech img'
          onLoad={e => {
            this.setState({
              startAnimation: true,
            });
          }}
          className={classname(Style.mainBg, { [Style.mainBgAnimation]: this.state.startAnimation })}
        />
        <div className={classname(Style.rightBox, { [Style.rightImgAnimation]: this.state.startAnimation })}>
          <img
            className={Style.rightImg}
            draggable={false}
            src={`https://resource.flexclip.com/pages/home/choiceness/Translate-right-1.webp?v=${FJConfig.buildVersion}`}
            width={521}
            height={723}
            alt='AI BG Remover main img'
          />
        </div>
        <div
          className={classname(Style.floatContainer, { [Style.floatContainerAnimation]: this.state.startAnimation })}
        >
          <img draggable={false} src={leftImg} width={415} height={220} alt='Text To Speech intro1' />
          <div onClick={this.chooseFile} className={Style.buttonBox}>
            <FJSvg src={TranslateSvg} />
            {data['button-1'] || 'Generate'}
          </div>
        </div>

        <input
          ref={e => (this.inputRef = e)}
          type='file'
          accept={this.inputAccept.map(item => '.' + item).join(',')}
          style={{ display: 'none' }}
          onClick={e => {
            e.target.value = null;
          }}
          onChange={e => {
            this.saveFileThenJump(e.target.files);
          }}
        />
      </div>
    );
  }
}
