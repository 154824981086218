import React, { Component } from 'react';

import Style from './TextToSpeech.module.less';
import FJButtonBrick from '../../../ButtonBrick/FJButtonBrick';
import FJSvg from '../../../FJSvg/FJSvg';
import GenerateSpeechSvg from '../../../../src/assets/icon/GenerateSpeech.svg';
import MusicSvg from '../../../../src/assets/icon/music.svg';
import FJUtil from '../../../../src/util/FJUtil';
import FJConfig from '../../../../src/config/FJConfig';

export default class TextToSpeech extends Component {
  constructor(props) {
    super(props);
    this.state = {
      keyword: '',
      isImageLoaded: 0,
    };
    this.timer1 = null;
    this.timer2 = null;
  }

  jumpToEditPage = () => {
    FJUtil.ealog('home jump', {
      'click name': 'Al Text to Speech',
    });
    window.location.href = FJUtil.getCurrentUrl() + '/editor/app?tools=text-to-speech';
  };

  imgAnimation = e => {
    e.target.style.visibility = 'visible';
    e.target.style.opacity = '1';
    e.target.style.transform = 'translateX(0)';

    this.timer1 = setTimeout(() => {
      this.observerDom.style.visibility = 'visible';
      this.observerDom.style.opacity = '1';
      this.observerDom.style.transform = 'translateY(0)';

      this.timer2 = setTimeout(() => {
        this.observerDomInput.style.visibility = 'visible';
        this.observerDomInput.style.opacity = '1';
        this.observerDomInput.style.transform = 'translateY(0)';
      }, 300);
    }, 500);
  };

  componentDidMount() {
    this.setState({
      isImageLoaded: this.state.isImageLoaded + 1,
    });
  }

  componentWillUnmount() {
    this.timer1 && clearTimeout(this.timer1);
    this.timer2 && clearTimeout(this.timer2);
  }

  render() {
    const { data = {}, language = '' } = this.props;
    const imgPrefix = `https://resource.flexclip.com/pages/home/choiceness/${language === 'jp' ? 'jp-' : ''}`;
    const mainImg = `https://resource.flexclip.com/pages/home/choiceness/TextToSpeech.webp?v=${FJConfig.buildVersion}`;
    const leftImg = imgPrefix + `TextToSpeech-intro1.webp?v=${FJConfig.buildVersion}`;
    return (
      <div className={Style.container}>
        <img
          key={this.state.isImageLoaded}
          draggable={false}
          src={mainImg}
          width={845}
          height={660}
          alt='text to speech img'
          onLoad={e => {
            this.imgAnimation(e);
          }}
        />
        <div className={Style.inputBox} ref={e => (this.observerDomInput = e)}>
          <FJSvg src={MusicSvg} />
        </div>
        <div className={Style.floatContainer} ref={e => (this.observerDom = e)}>
          <img draggable={false} src={leftImg} width={415} height={220} alt='Text To Speech intro1' />
          <FJButtonBrick onClick={this.jumpToEditPage} className={Style.buttonBox} type='other'>
            <FJSvg src={GenerateSpeechSvg} />
            {data['button-1'] || 'Generate'}
          </FJButtonBrick>
        </div>
      </div>
    );
  }
}
