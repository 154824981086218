import { Component } from 'react';

import classname from 'classnames';

import FJSvg from '../../../FJSvg/FJSvg';

import Style from './AIVideoGenerator.module.less';

import GenerateVideoSvg from '../../../../src/assets/icon/GenerateVideo.svg';
import FJUtil from '../../../../src/util/FJUtil';
import FJConfig from '../../../../src/config/FJConfig';

export default class AIVideoGenerator extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isImageLoaded: 0,
    };

    this.timer1 = null;
    this.timer2 = null;
  }

  jumpToEditPage = () => {
    FJUtil.ealog('home jump', {
      'click name': 'Al Video Generator',
    });
    window.location.href = FJUtil.getCurrentUrl() + '/editor/app?module=ai-video-generator';
  };

  addAnimation = () => {
    this.observerDom.style.visibility = 'visible';
    this.observerDom.style.opacity = '1';
    this.observerDom.style.transform = 'translateY(0)';
    clearTimeout(this.timer2);

    this.timer2 = setTimeout(() => {
      this.observerDomInput.style.visibility = 'visible';
      this.observerDomInput.style.opacity = '1';
      this.observerDomInput.style.transform = 'translateY(0)';
    }, 300);
  };

  imgAnimation = e => {
    e.target.style.visibility = 'visible';
    e.target.style.opacity = '1';
    e.target.style.transform = 'translateX(0)';
    clearTimeout(this.timer1);

    this.timer1 = setTimeout(() => {
      this.addAnimation();
    }, 600);
  };

  componentDidMount() {
    this.setState({
      isImageLoaded: this.state.isImageLoaded + 1,
    });
  }

  componentWillUnmount() {
    this.timer1 && clearTimeout(this.timer1);
    this.timer2 && clearTimeout(this.timer2);
  }

  render() {
    const { data = {}, language = '' } = this.props;

    const imgPrefix = `https://resource.flexclip.com/pages/home/choiceness/${language === 'jp' ? 'jp-' : ''}`;
    const mainImg = `https://resource.flexclip.com/pages/home/choiceness/AIVideoGenerator.webp?v=${FJConfig.buildVersion}`;
    const leftImg = imgPrefix + `AIVideoGenerator-intro.webp?v=${FJConfig.buildVersion}`;
    return (
      <div className={Style.container}>
        <img
          key={this.state.isImageLoaded}
          draggable={false}
          src={mainImg}
          width={892}
          height={677}
          alt='AI Video Generator img'
          onLoad={e => {
            this.imgAnimation(e);
          }}
        />
        <div className={Style.inputBox} ref={e => (this.observerDomInput = e)}>
          {data['input-desc'] || 'Describe the video you want to generate or paste url'}
        </div>
        <div className={classname('observerDom', Style.floatContainer)} ref={e => (this.observerDom = e)}>
          <img draggable={false} src={leftImg} width={336} height={371} alt='AI Video Generator intro' />
          <div onClick={this.jumpToEditPage} className={Style.buttonBox}>
            <FJSvg src={GenerateVideoSvg} />
            {data['button-1'] || 'Generate'}
          </div>
        </div>
      </div>
    );
  }
}
